@use "sass:math";
/*--------------------------------------------------------------------------------------------
*
*  Global
*
*--------------------------------------------------------------------------------------------*/

/* Horizontal List */
.acf-hl {
	padding: 0;
	margin: 0;
	list-style: none;
	display: block;
	position: relative;
}
.acf-hl > li {
	float: left;
	display: block;
	margin: 0;
	padding: 0;
}
.acf-hl > li.acf-fr {
	float: right;
}

/* Horizontal List: Clearfix */
.acf-hl:before,
.acf-hl:after,
.acf-bl:before,
.acf-bl:after,
.acf-cf:before,
.acf-cf:after {
	content: "";
	display: block;
	line-height: 0;
}
.acf-hl:after,
.acf-bl:after,
.acf-cf:after {
	clear: both;
}

/* Block List */
.acf-bl {
	padding: 0;
	margin: 0;
	list-style: none;
	display: block;
	position: relative;
}
.acf-bl > li {
	display: block;
	margin: 0;
	padding: 0;
	float: none;
}

/* Visibility */
.acf-hidden {
	display: none !important;
}
.acf-empty {
	display: table-cell !important;
	* {
		display: none !important;
	}
}

/* Float */
.acf-fl {
	float: left;
}
.acf-fr {
	float: right;
}
.acf-fn {
	float: none;
}

/* Align */
.acf-al {
	text-align: left;
}
.acf-ar {
	text-align: right;
}
.acf-ac {
	text-align: center;
}

/* loading */
.acf-loading,
.acf-spinner {
	display: inline-block;
	height: 20px;
	width: 20px;
	vertical-align: text-top;
	background: transparent url(../../images/spinner.gif) no-repeat 50% 50%;
}

/* spinner */
.acf-spinner {
	display: none;
}

.acf-spinner.is-active {
	display: inline-block;
}

/* WP < 4.2 */
.spinner.is-active {
	display: inline-block;
}

/* required */
.acf-required {
	color: #f00;
}

/* Allow pointer events in reusable blocks */
.acf-button,
.acf-tab-button {
	pointer-events: auto !important;
}

/* show on hover */
.acf-soh .acf-soh-target {
	-webkit-transition: opacity 0.25s 0s ease-in-out, visibility 0s linear 0.25s;
	-moz-transition: opacity 0.25s 0s ease-in-out, visibility 0s linear 0.25s;
	-o-transition: opacity 0.25s 0s ease-in-out, visibility 0s linear 0.25s;
	transition: opacity 0.25s 0s ease-in-out, visibility 0s linear 0.25s;

	visibility: hidden;
	opacity: 0;
}

.acf-soh:hover .acf-soh-target {
	-webkit-transition-delay: 0s;
	-moz-transition-delay: 0s;
	-o-transition-delay: 0s;
	transition-delay: 0s;

	visibility: visible;
	opacity: 1;
}

/* show if value */
.show-if-value {
	display: none;
}
.hide-if-value {
	display: block;
}

.has-value .show-if-value {
	display: block;
}
.has-value .hide-if-value {
	display: none;
}

/* select2 WP animation fix */
.select2-search-choice-close {
	-webkit-transition: none;
	-moz-transition: none;
	-o-transition: none;
	transition: none;
}

/*---------------------------------------------------------------------------------------------
*
*  tooltip
*
*---------------------------------------------------------------------------------------------*/

/* tooltip */
.acf-tooltip {
	background: $gray-800;
	border-radius: $radius-md;
	color: $gray-300;
	padding: {
		top: 8px;
		right: 12px;
		bottom: 10px;
		left: 12px;
	}
	position: absolute;
	@extend .p7;
	z-index: 900000;
	max-width: 280px;
	box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
		0px 4px 6px -2px rgba(16, 24, 40, 0.03);

	/* tip */
	&:before {
		border: solid;
		border-color: transparent;
		border-width: 6px;
		content: "";
		position: absolute;
	}

	/* positions */
	&.top {
		margin-top: -8px;

		&:before {
			top: 100%;
			left: 50%;
			margin-left: -6px;
			border-top-color: #2f353e;
			border-bottom-width: 0;
		}
	}

	&.right {
		margin-left: 8px;

		&:before {
			top: 50%;
			margin-top: -6px;
			right: 100%;
			border-right-color: #2f353e;
			border-left-width: 0;
		}
	}

	&.bottom {
		margin-top: 8px;

		&:before {
			bottom: 100%;
			left: 50%;
			margin-left: -6px;
			border-bottom-color: #2f353e;
			border-top-width: 0;
		}
	}

	&.left {
		margin-left: -8px;

		&:before {
			top: 50%;
			margin-top: -6px;
			left: 100%;
			border-left-color: #2f353e;
			border-right-width: 0;
		}
	}

	.acf-overlay {
		z-index: -1;
	}
}

/* confirm */
.acf-tooltip.-confirm {
	z-index: 900001; // +1 higher than .acf-tooltip

	a {
		text-decoration: none;
		color: #9ea3a8;

		&:hover {
			text-decoration: underline;
		}

		&[data-event="confirm"] {
			color: #f55e4f;
		}
	}
}

.acf-overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	cursor: default;
}

.acf-tooltip-target {
	position: relative;
	z-index: 900002; // +1 higher than .acf-tooltip
}

/*---------------------------------------------------------------------------------------------
*
*  loading
*
*---------------------------------------------------------------------------------------------*/
.acf-loading-overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	cursor: default;
	z-index: 99;
	background: rgba(249, 249, 249, 0.5);

	i {
		@include centered();
	}
}

/*--------------------------------------------------------------------------------------------
*
*	acf-icon
*
*--------------------------------------------------------------------------------------------*/
.acf-icon {
	display: inline-block;
	height: 28px;
	width: 28px;
	border: transparent solid 1px;
	border-radius: 100%;
	font-size: 20px;
	line-height: 21px;
	text-align: center;
	text-decoration: none;
	vertical-align: top;
	box-sizing: border-box;

	&:before {
		font-family: dashicons;
		display: inline-block;
		line-height: 1;
		font-weight: 400;
		font-style: normal;
		speak: none;
		text-decoration: inherit;
		text-transform: none;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		width: 1em;
		height: 1em;
		vertical-align: middle;
		text-align: center;
	}
}

// Icon types.
.acf-icon.-plus:before {
	content: "\f543";
}
.acf-icon.-minus:before {
	content: "\f460";
}
.acf-icon.-cancel:before {
	content: "\f335";
	margin: -1px 0 0 -1px;
}
.acf-icon.-pencil:before {
	content: "\f464";
}
.acf-icon.-location:before {
	content: "\f230";
}
.acf-icon.-up:before {
	content: "\f343";

	// Fix position relative to font-size.
	margin-top: math.div(-2em, 20);
}
.acf-icon.-down:before {
	content: "\f347";

	// Fix position relative to font-size.
	margin-top: math.div(2em, 20);
}
.acf-icon.-left:before {
	content: "\f341";

	// Fix position relative to font-size.
	margin-left: math.div(-2em, 20);
}
.acf-icon.-right:before {
	content: "\f345";

	// Fix position relative to font-size.
	margin-left: math.div(2em, 20);
}
.acf-icon.-sync:before {
	content: "\f463";
}
.acf-icon.-globe:before {
	content: "\f319";

	// Fix position relative to font-size.
	margin-top: math.div(2em, 20);
	margin-left: math.div(2em, 20);
}
.acf-icon.-picture:before {
	content: "\f128";
}
.acf-icon.-check:before {
	content: "\f147";

	// Fix position relative to font-size.
	margin-left: math.div(-2em, 20);
}
.acf-icon.-dot-3:before {
	content: "\f533";

	// Fix position relative to font-size.
	margin-top: math.div(-2em, 20);
}
.acf-icon.-arrow-combo:before {
	content: "\f156";
}
.acf-icon.-arrow-up:before {
	content: "\f142";

	// Fix position relative to font-size.
	margin-left: math.div(-2em, 20);
}
.acf-icon.-arrow-down:before {
	content: "\f140";

	// Fix position relative to font-size.
	margin-left: math.div(-2em, 20);
}
.acf-icon.-search:before {
	content: "\f179";
}
.acf-icon.-link-ext:before {
	content: "\f504";
}

// Duplicate is a custom icon made from pseudo elements.
.acf-icon.-duplicate {
	position: relative;
	&:before,
	&:after {
		content: "";
		display: block;
		box-sizing: border-box;
		width: 46%;
		height: 46%;
		position: absolute;
		top: 33%;
		left: 23%;
	}
	&:before {
		margin: -1px 0 0 1px;
		box-shadow: 2px -2px 0px 0px currentColor;
	}
	&:after {
		border: solid 2px currentColor;
	}
}

.acf-icon.-trash {
	position: relative;
	&:before,
	&:after {
		content: "";
		display: block;
		box-sizing: border-box;
		width: 46%;
		height: 46%;
		position: absolute;
		top: 33%;
		left: 23%;
	}
	&:before {
		margin: -1px 0 0 1px;
		box-shadow: 2px -2px 0px 0px currentColor;
	}
	&:after {
		border: solid 2px currentColor;
	}
}

// Collapse icon toggles automatically.
.acf-icon.-collapse:before {
	content: "\f142";

	// Fix position relative to font-size.
	margin-left: math.div(-2em, 20);
}
.-collapsed .acf-icon.-collapse:before {
	content: "\f140";

	// Fix position relative to font-size.
	margin-left: math.div(-2em, 20);
}

// <span> displays with grey border.
span.acf-icon {
	color: #555d66;
	border-color: #b5bcc2;
	background-color: #fff;
}

// <a> also displays with grey border.
a.acf-icon {
	color: #555d66;
	border-color: #b5bcc2;
	background-color: #fff;
	position: relative;
	transition: none;
	cursor: pointer;

	// State "hover".
	&:hover {
		background: #f3f5f6;
		border-color: #0071a1;
		color: #0071a1;
	}
	&.-minus:hover,
	&.-cancel:hover {
		background: #f7efef;
		border-color: #a10000;
		color: #dc3232;
	}

	// Fix: Remove WP outline box-shadow.
	&:active,
	&:focus {
		outline: none;
		box-shadow: none;
	}
}

// Style "clear".
.acf-icon.-clear {
	border-color: transparent;
	background: transparent;
	color: #444;
}

// Style "light".
.acf-icon.light {
	border-color: transparent;
	background: #f5f5f5;
	color: #23282d;
}

// Style "dark".
.acf-icon.dark {
	border-color: transparent !important;
	background: #23282d;
	color: #eee;
}
a.acf-icon.dark {
	&:hover {
		background: #191e23;
		color: #00b9eb;
	}
	&.-minus:hover,
	&.-cancel:hover {
		color: #d54e21;
	}
}

// Style "grey".
.acf-icon.grey {
	border-color: transparent !important;
	background: #b4b9be;
	color: #fff !important;

	&:hover {
		background: #00a0d2;
		color: #fff;
	}
	&.-minus:hover,
	&.-cancel:hover {
		background: #32373c;
	}
}

// Size "small".
.acf-icon.small,
.acf-icon.-small {
	width: 20px;
	height: 20px;
	line-height: 14px;
	font-size: 14px;

	// Apply minor transforms to reduce clarirty of "duplicate" icon.
	// Helps to unify rendering with dashicons.
	&.-duplicate {
		&:before,
		&:after {
			//transform: rotate(0.1deg) scale(0.9) translate(-5%, 5%);
			opacity: 0.8;
		}
	}
}

/*--------------------------------------------------------------------------------------------
*
*	acf-box
*
*--------------------------------------------------------------------------------------------*/
.acf-box {
	background: #ffffff;
	border: 1px solid $wp-card-border;
	position: relative;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04);

	/* title */
	.title {
		border-bottom: 1px solid $wp-card-border;
		margin: 0;
		padding: 15px;

		h3 {
			display: flex;
			align-items: center;
			font-size: 14px;
			line-height: 1em;
			margin: 0;
			padding: 0;
		}
	}

	.inner {
		padding: 15px;
	}

	h2 {
		color: #333333;
		font-size: 26px;
		line-height: 1.25em;
		margin: 0.25em 0 0.75em;
		padding: 0;
	}

	h3 {
		margin: 1.5em 0 0;
	}

	p {
		margin-top: 0.5em;
	}

	a {
		text-decoration: none;
	}

	i {
		&.dashicons-external {
			margin-top: -1px;
		}
	}

	/* footer */
	.footer {
		border-top: 1px solid $wp-card-border;
		padding: 12px;
		font-size: 13px;
		line-height: 1.5;

		p {
			margin: 0;
		}
	}

	// WP Admin 3.8
	@include wp-admin("3-8") {
		border-color: $wp38-card-border;
		.title,
		.footer {
			border-color: $wp38-card-border;
		}
	}
}

/*--------------------------------------------------------------------------------------------
*
*	acf-notice
*
*--------------------------------------------------------------------------------------------*/

.acf-notice {
	position: relative;
	display: block;
	color: #fff;
	margin: 5px 0 15px;
	padding: 3px 12px;
	background: $acf_notice;
	border-left: darken($acf_notice, 10%) solid 3px;

	p {
		font-size: 13px;
		line-height: 1.5;
		margin: 0.5em 0;
		text-shadow: none;
		color: inherit;
	}

	.acf-notice-dismiss {
		position: absolute;
		top: 9px;
		right: 12px;
		background: transparent !important;
		color: inherit !important;
		border-color: #fff !important;
		opacity: 0.75;
		&:hover {
			opacity: 1;
		}
	}

	// dismiss
	&.-dismiss {
		padding-right: 40px;
	}

	// error
	&.-error {
		background: $acf_error;
		border-color: darken($acf_error, 10%);
	}

	// success
	&.-success {
		background: $acf_success;
		border-color: darken($acf_success, 10%);
	}

	// warning
	&.-warning {
		background: $acf_warning;
		border-color: darken($acf_warning, 10%);
	}
}

/*--------------------------------------------------------------------------------------------
*
*	acf-table
*
*--------------------------------------------------------------------------------------------*/

.acf-table {
	border: $wp-card-border solid 1px;
	background: #fff;
	border-spacing: 0;
	border-radius: 0;
	table-layout: auto;
	padding: 0;
	margin: 0;
	width: 100%;
	clear: both;
	box-sizing: content-box;

	/* defaults */
	> tbody > tr,
	> thead > tr {
		> th,
		> td {
			padding: 8px;
			vertical-align: top;
			background: #fff;
			text-align: left;
			border-style: solid;
			font-weight: normal;
		}

		> th {
			position: relative;
			color: #333333;
		}
	}

	/* thead */
	> thead {
		> tr {
			> th {
				border-color: $wp-card-border-1;
				border-width: 0 0 1px 1px;

				&:first-child {
					border-left-width: 0;
				}
			}
		}
	}

	/* tbody */
	> tbody {
		> tr {
			z-index: 1;

			> td {
				border-color: $wp-card-border-2;
				border-width: 1px 0 0 1px;

				&:first-child {
					border-left-width: 0;
				}
			}

			&:first-child > td {
				border-top-width: 0;
			}
		}
	}

	/* -clear */
	&.-clear {
		border: 0 none;

		> tbody > tr,
		> thead > tr {
			> td,
			> th {
				border: 0 none;
				padding: 4px;
			}
		}
	}
}

/* remove tr */
.acf-remove-element {
	-webkit-transition: all 0.25s ease-out;
	-moz-transition: all 0.25s ease-out;
	-o-transition: all 0.25s ease-out;
	transition: all 0.25s ease-out;

	transform: translate(50px, 0);
	opacity: 0;
}

/* fade-up */
.acf-fade-up {
	-webkit-transition: all 0.25s ease-out;
	-moz-transition: all 0.25s ease-out;
	-o-transition: all 0.25s ease-out;
	transition: all 0.25s ease-out;

	transform: translate(0, -10px);
	opacity: 0;
}

/*---------------------------------------------------------------------------------------------
*
*  Fake table
*
*---------------------------------------------------------------------------------------------*/

.acf-thead,
.acf-tbody,
.acf-tfoot {
	width: 100%;
	padding: 0;
	margin: 0;

	> li {
		box-sizing: border-box;
		padding: {
			top: 14px;
		}
		font-size: 12px;
		line-height: 14px;
	}
}

.acf-thead {
	border-bottom: $wp-card-border solid 1px;
	color: #23282d;

	> li {
		font-size: 14px;
		line-height: 1.4;
		font-weight: bold;
	}

	// WP Admin 3.8
	@include wp-admin("3-8") {
		border-color: $wp38-card-border-1;
	}
}

.acf-tfoot {
	background: #f5f5f5;
	border-top: $wp-card-border-1 solid 1px;
}

/*--------------------------------------------------------------------------------------------
*
*	Settings
*
*--------------------------------------------------------------------------------------------*/

.acf-settings-wrap {
	#poststuff {
		padding-top: 15px;
	}

	.acf-box {
		margin: 20px 0;
	}

	table {
		margin: 0;

		.button {
			vertical-align: middle;
		}
	}
}

/*--------------------------------------------------------------------------------------------
*
*	acf-popup
*
*--------------------------------------------------------------------------------------------*/

#acf-popup {
	position: fixed;
	z-index: 900000;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	text-align: center;

	// bg
	.bg {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 0;
		background: rgba(0, 0, 0, 0.25);
	}

	&:before {
		content: "";
		display: inline-block;
		height: 100%;
		vertical-align: middle;
	}

	// box
	.acf-popup-box {
		display: inline-block;
		vertical-align: middle;
		z-index: 1;
		min-width: 300px;
		min-height: 160px;
		border-color: #aaaaaa;
		box-shadow: 0 5px 30px -5px rgba(0, 0, 0, 0.25);
		text-align: left;
		@include rtl();

		// title
		.title {
			min-height: 15px;
			line-height: 15px;

			// icon
			.acf-icon {
				position: absolute;
				top: 10px;
				right: 10px;

				// rtl
				html[dir="rtl"] & {
					right: auto;
					left: 10px;
				}
			}
		}

		.inner {
			min-height: 50px;

			// use margin instead of padding to allow inner elements marin to overlap and avoid large hitespace at top/bottom
			padding: 0;
			margin: 15px;
		}

		// loading
		.loading {
			position: absolute;
			top: 45px;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 2;
			background: rgba(0, 0, 0, 0.1);
			display: none;

			i {
				@include centered();
			}
		}
	}
}

// acf-submit
.acf-submit {
	margin-bottom: 0;
	line-height: 28px; // .button height

	// message
	span {
		float: right;
		color: #999;

		&.-error {
			color: #dd4232;
		}
	}

	// button (allow margin between loading)
	.button {
		margin-right: 5px;
	}
}

/*--------------------------------------------------------------------------------------------
*
*	upgrade notice
*
*--------------------------------------------------------------------------------------------*/

#acf-upgrade-notice {
	position: relative;
	background: #fff;
	padding: 20px;
	@include clearfix();

	.col-content {
		float: left;
		width: 55%;
		padding-left: 90px;
	}

	.notice-container {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		align-content: flex-start;
	}

	.col-actions {
		float: right;
		text-align: center;
	}

	img {
		float: left;
		width: 64px;
		height: 64px;
		margin: 0 0 0 -90px;
	}

	h2 {
		display: inline-block;
		font-size: 16px;
		margin: 2px 0 6.5px;
	}

	p {
		padding: 0;
		margin: 0;
	}

	.button:before {
		margin-top: 11px;
	}

	// mobile
	@media screen and (max-width: $sm) {
		.col-content,
		.col-actions {
			float: none;
			padding-left: 90px;
			width: auto;
			text-align: left;
		}
	}
}

// Hide icons for upgade notice.
#acf-upgrade-notice:has(.notice-container)::before,
#acf-upgrade-notice:has(.notice-container)::after {
	display: none;
}

// Match padding of other non-icon notices.
#acf-upgrade-notice:has(.notice-container) {
	padding-left: 20px !important;
}

/*--------------------------------------------------------------------------------------------
*
*	Welcome
*
*--------------------------------------------------------------------------------------------*/

.acf-wrap {
	h1 {
		margin-top: 0;
		padding-top: 20px;
	}

	.about-text {
		margin-top: 0.5em;
		min-height: 50px;
	}

	.about-headline-callout {
		font-size: 2.4em;
		font-weight: 300;
		line-height: 1.3;
		margin: 1.1em 0 0.2em;
		text-align: center;
	}

	.feature-section {
		padding: 40px 0;

		h2 {
			margin-top: 20px;
		}
	}

	.changelog {
		list-style: disc;
		padding-left: 15px;

		li {
			margin: 0 0 0.75em;
		}
	}

	.acf-three-col {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		> div {
			flex: 1;
			align-self: flex-start;
			min-width: 31%;
			max-width: 31%;

			@media screen and (max-width: $md) {
				min-width: 48%;
			}

			@media screen and (max-width: $sm) {
				min-width: 100%;
			}
		}

		h3 .badge {
			display: inline-block;
			vertical-align: top;
			border-radius: 5px;
			background: #fc9700;
			color: #fff;
			font-weight: normal;
			font-size: 12px;
			padding: 2px 5px;
		}

		img + h3 {
			margin-top: 0.5em;
		}
	}
}

/*--------------------------------------------------------------------------------------------
*
*	acf-hl cols
*
*--------------------------------------------------------------------------------------------*/

.acf-hl[data-cols] {
	margin-left: -10px;
	margin-right: -10px;

	> li {
		padding: 0 6px 0 10px;

		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
}

/* sizes */
.acf-hl[data-cols="2"] > li {
	width: 50%;
}
.acf-hl[data-cols="3"] > li {
	width: 33.333%;
}
.acf-hl[data-cols="4"] > li {
	width: 25%;
}

/* mobile */
@media screen and (max-width: $sm) {
	.acf-hl[data-cols] {
		flex-wrap: wrap;
		justify-content: flex-start;
		align-content: flex-start;
		align-items: flex-start;
		margin-left: 0;
		margin-right: 0;
		margin-top: -10px;

		> li {
			flex: 1 1 100%;
			width: 100% !important;
			padding: 10px 0 0;
		}
	}
}

/*--------------------------------------------------------------------------------------------
*
*	misc
*
*--------------------------------------------------------------------------------------------*/

.acf-actions {
	text-align: right;
	z-index: 1;

	/* hover */
	&.-hover {
		position: absolute;
		display: none;
		top: 0;
		right: 0;
		padding: 5px;
		z-index: 1050;
	}

	/* rtl */
	html[dir="rtl"] & {
		&.-hover {
			right: auto;
			left: 0;
		}
	}
}

/* ul compatibility */
ul.acf-actions {
	li {
		float: right;
		margin-left: 4px;
	}
}

/*--------------------------------------------------------------------------------------------
*
*	RTL
*
*--------------------------------------------------------------------------------------------*/

html[dir="rtl"] .acf-fl {
	float: right;
}
html[dir="rtl"] .acf-fr {
	float: left;
}

html[dir="rtl"] .acf-hl > li {
	float: right;
}

html[dir="rtl"] .acf-hl > li.acf-fr {
	float: left;
}

html[dir="rtl"] .acf-icon.logo {
	left: 0;
	right: auto;
}

html[dir="rtl"] .acf-table thead th {
	text-align: right;
	border-right-width: 1px;
	border-left-width: 0px;
}

html[dir="rtl"] .acf-table > tbody > tr > td {
	text-align: right;
	border-right-width: 1px;
	border-left-width: 0px;
}

html[dir="rtl"] .acf-table > thead > tr > th:first-child,
html[dir="rtl"] .acf-table > tbody > tr > td:first-child {
	border-right-width: 0;
}

html[dir="rtl"] .acf-table > tbody > tr > td.order + td {
	border-right-color: #e1e1e1;
}

/*---------------------------------------------------------------------------------------------
*
*  acf-postbox-columns
*
*---------------------------------------------------------------------------------------------*/

.acf-postbox-columns {
	@include clearfix();
	position: relative;
	margin-top: -11px;
	margin-bottom: -12px;
	margin-left: -12px;
	margin-right: (280px - 12px);

	.acf-postbox-main,
	.acf-postbox-side {
		@include border-box();
		padding: 0 12px 12px;
	}

	.acf-postbox-main {
		float: left;
		width: 100%;
	}

	.acf-postbox-side {
		float: right;
		width: 280px;
		margin-right: -280px;

		&:before {
			content: "";
			display: block;
			position: absolute;
			width: 1px;
			height: 100%;
			top: 0;
			right: 0;
			background: $wp-card-border-1;
		}
	}

	// WP Admin 3.8
	@include wp-admin("3-8") {
		.acf-postbox-side:before {
			background: $wp38-card-border-1;
		}
	}
}

/* mobile */
@media only screen and (max-width: 850px) {
	.acf-postbox-columns {
		margin: 0;

		.acf-postbox-main,
		.acf-postbox-side {
			float: none;
			width: auto;
			margin: 0;
			padding: 0;
		}

		.acf-postbox-side {
			margin-top: 1em;

			&:before {
				display: none;
			}
		}
	}
}

/*---------------------------------------------------------------------------------------------
*
*  acf-panel
*
*---------------------------------------------------------------------------------------------*/

.acf-panel {
	margin-top: -1px;
	border-top: 1px solid $wp-card-border-1;
	border-bottom: 1px solid $wp-card-border-1;

	.acf-panel-title {
		margin: 0;
		padding: 12px;
		font-weight: bold;
		cursor: pointer;
		font-size: inherit;

		i {
			float: right;
		}
	}

	.acf-panel-inside {
		margin: 0;
		padding: 0 12px 12px;
		display: none;
	}

	/* open */
	&.-open {
		.acf-panel-inside {
			display: block;
		}
	}

	/* inside postbox */
	.postbox & {
		margin-left: -12px;
		margin-right: -12px;
	}

	/* fields */
	.acf-field {
		margin: 20px 0 0;

		.acf-label label {
			color: #555d66;
			font-weight: normal;
		}

		&:first-child {
			margin-top: 0;
		}
	}

	// WP Admin 3.8
	@include wp-admin("3-8") {
		border-color: $wp38-card-border-1;
	}
}

/*---------------------------------------------------------------------------------------------
*
*  Admin Tools
*
*---------------------------------------------------------------------------------------------*/

#acf-admin-tools {
	.notice {
		margin-top: 10px;
	}

	.acf-meta-box-wrap {
		.inside {
			border-top: none;
		}

		/* acf-fields */
		.acf-fields {
			margin: {
				bottom: 24px;
			}
			border: none;
			background: #fff;
			border-radius: 0;

			.acf-field {
				padding: 0;
				margin-bottom: 19px;
				border-top: none;
			}

			.acf-label {
				@extend .p2;
				margin: {
					bottom: 16px;
				}
			}

			.acf-input {
				padding: {
					top: 16px;
					right: 16px;
					bottom: 16px;
					left: 16px;
				}
				border: {
					width: 1px;
					style: solid;
					color: $gray-300;
				}
				border-radius: $radius-md;
			}

			&.import-cptui {
				margin-top: 19px;
			}
		}
	}
}

.acf-meta-box-wrap {
	.postbox {
		@include border-box();

		.inside {
			margin-bottom: 0;
		}

		.hndle {
			font-size: 14px;
			padding: 8px 12px;
			margin: 0;
			line-height: 1.4;

			// Prevent .acf-panel border overlapping.
			position: relative;
			z-index: 1;
			cursor: default;
		}

		.handlediv,
		.handle-order-higher,
		.handle-order-lower {
			display: none;
		}
	}
}

/* grid */
.acf-meta-box-wrap.-grid {
	margin-left: 8px;
	margin-right: 8px;

	.postbox {
		float: left;
		clear: left;
		width: 50%;
		margin: 0 0 16px;

		&:nth-child(odd) {
			margin-left: -8px;
		}

		&:nth-child(even) {
			float: right;
			clear: right;
			margin-right: -8px;
		}
	}
}

/* mobile */
@media only screen and (max-width: 850px) {
	.acf-meta-box-wrap.-grid {
		margin-left: 0;
		margin-right: 0;

		.postbox {
			margin-left: 0 !important;
			margin-right: 0 !important;
			width: 100%;
		}
	}
}

/* export tool */
#acf-admin-tool-export {
	p {
		max-width: 800px;
	}

	ul {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		li {
			flex: 0 1 33.33%;
			@media screen and (max-width: 1600px) {
				flex: 0 1 50%;
			}
			@media screen and (max-width: 1200px) {
				flex: 0 1 100%;
			}
		}
	}

	.acf-postbox-side {
		ul {
			display: block;
		}

		.button {
			margin: 0;
			width: 100%;
		}
	}

	textarea {
		display: block;
		width: 100%;
		min-height: 500px;
		background: $gray-50;
		border-color: $gray-300;
		box-shadow: none;
		padding: 7px;
		border-radius: $radius-md;
	}

	/* panel: selection */
	.acf-panel-selection {
		.acf-label label {
			font-weight: bold;
			color: $gray-700;
		}
	}
}

#acf-admin-tool-import {
	ul {
		column-width: 200px;
	}
}

// CSS only Tooltip.
.acf-css-tooltip {
	position: relative;
	&:before {
		content: attr(aria-label);
		display: none;
		position: absolute;
		z-index: 999;

		bottom: 100%;
		left: 50%;
		transform: translate(-50%, -8px);

		background: #191e23;
		border-radius: 2px;
		padding: 5px 10px;

		color: #fff;
		font-size: 12px;
		line-height: 1.4em;
		white-space: pre;
	}
	&:after {
		content: "";
		display: none;
		position: absolute;
		z-index: 998;

		bottom: 100%;
		left: 50%;
		transform: translate(-50%, 4px);

		border: solid 6px transparent;
		border-top-color: #191e23;
	}

	&:hover,
	&:focus {
		&:before,
		&:after {
			display: block;
		}
	}
}

// Diff modal.
.acf-diff {
	.acf-diff-title {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 40px;
		padding: 14px 16px;
		background: #f3f3f3;
		border-bottom: #dddddd solid 1px;

		strong {
			font-size: 14px;
			display: block;
		}

		.acf-diff-title-left,
		.acf-diff-title-right {
			width: 50%;
			float: left;
		}
	}

	.acf-diff-content {
		position: absolute;
		top: 70px;
		left: 0;
		right: 0;
		bottom: 0;
		overflow: auto;
	}

	table.diff {
		border-spacing: 0;

		col.diffsplit.middle {
			width: 0;
		}

		td,
		th {
			padding-top: 0.25em;
			padding-bottom: 0.25em;
		}

		// Fix WP 5.7 conflicting CSS.
		tr td:nth-child(2) {
			width: auto;
		}

		td:nth-child(3) {
			border-left: #dddddd solid 1px;
		}
	}

	// Mobile
	@media screen and (max-width: 600px) {
		.acf-diff-title {
			height: 70px;
		}
		.acf-diff-content {
			top: 100px;
		}
	}
}

/*---------------------------------------------------------------------------------------------
*
*  Modal
*
*---------------------------------------------------------------------------------------------*/
.acf-modal {
	position: fixed;
	top: 30px;
	left: 30px;
	right: 30px;
	bottom: 30px;
	z-index: 160000;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.7);
	background: #fcfcfc;

	.acf-modal-title,
	.acf-modal-content,
	.acf-modal-toolbar {
		box-sizing: border-box;
		position: absolute;
		left: 0;
		right: 0;
	}

	.acf-modal-title {
		height: 50px;
		top: 0;
		border-bottom: 1px solid #ddd;

		h2 {
			margin: 0;
			padding: 0 16px;
			line-height: 50px;
		}
		.acf-modal-close {
			position: absolute;
			top: 0;
			right: 0;
			height: 50px;
			width: 50px;
			border: none;
			border-left: 1px solid #ddd;
			background: transparent;
			cursor: pointer;
			color: #666;
			&:hover {
				color: #00a0d2;
			}
		}
	}

	.acf-modal-content {
		top: 50px;
		bottom: 60px;
		background: #fff;
		overflow: auto;
		padding: 16px;
	}

	.acf-modal-feedback {
		position: absolute;
		top: 50%;
		margin: -10px 0;
		left: 0;
		right: 0;
		text-align: center;
		opacity: 0.75;

		&.error {
			opacity: 1;
			color: #b52727;
		}
	}

	.acf-modal-toolbar {
		height: 60px;
		bottom: 0;
		padding: 15px 16px;
		border-top: 1px solid #ddd;

		.button {
			float: right;
		}
	}

	// Responsive.
	@media only screen and (max-width: 640px) {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}
.acf-modal-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: $gray-900;
	opacity: 0.8;
	z-index: 159900;
}

/*---------------------------------------------------------------------------------------------
*
*  Retina
*
*---------------------------------------------------------------------------------------------*/

@media only screen and (-webkit-min-device-pixel-ratio: 2),
	only screen and (min--moz-device-pixel-ratio: 2),
	only screen and (-o-min-device-pixel-ratio: 2/1),
	only screen and (min-device-pixel-ratio: 2),
	only screen and (min-resolution: 192dpi),
	only screen and (min-resolution: 2dppx) {
	.acf-loading,
	.acf-spinner {
		background-image: url(../../images/spinner@2x.gif);
		background-size: 20px 20px;
	}
}

/*--------------------------------------------------------------------------------------------
*
*  Wrap
*
*--------------------------------------------------------------------------------------------*/

.acf-admin-page {
	.wrap {
		margin: {
			top: 48px;
			right: 32px;
			bottom: 0;
			left: 12px;
		}

		@media screen and (max-width: 768px) {
			margin: {
				right: 8px;
				left: 8px;
			}
		}
	}

	&.rtl .wrap {
		margin: {
			right: 12px;
			left: 32px;
		}

		@media screen and (max-width: 768px) {
			margin: {
				right: 8px;
				left: 8px;
			}
		}
	}

	#wpcontent {
		@media screen and (max-width: 768px) {
			padding: {
				left: 0;
			}
		}
	}
}

/*-------------------------------------------------------------------
*
*  ACF Admin Page Footer Styles
*
*------------------------------------------------------------------*/
.acf-admin-page {
	#wpfooter {
		font-style: italic;
	}
}

/*---------------------------------------------------------------------------------------------
*
*  Admin Postbox & ACF Postbox
*
*---------------------------------------------------------------------------------------------*/
.acf-admin-page {
	.postbox,
	.acf-box {
		border: none;
		border-radius: $radius-lg;
		box-shadow: $elevation-01;

		.inside {
			padding: {
				top: 24px;
				right: 24px;
				bottom: 24px;
				left: 24px;
			}
		}

		.acf-postbox-inner {
			margin: {
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}
			padding: {
				top: 24px;
				right: 0;
				bottom: 0;
				left: 0;
			}
		}

		.inner,
		.inside {
			margin: {
				top: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				left: 0 !important;
			}
			border-top: {
				width: 1px;
				style: solid;
				color: $gray-200;
			}
		}

		.postbox-header,
		.title {
			display: flex;
			align-items: center;
			box-sizing: border-box;
			min-height: 64px;
			margin: {
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}
			padding: {
				top: 0;
				right: 24px;
				bottom: 0;
				left: 24px;
			}
			border-bottom: {
				width: 0;
				style: none;
			}

			h2,
			h3 {
				margin: {
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
				}
				padding: {
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
				}
				@extend .acf-h3;
				color: $gray-700;
			}
		}

		.hndle {
			padding: {
				top: 0;
				right: 24px;
				bottom: 0;
				left: 24px;
			}
		}
	}
}

/*---------------------------------------------------------------------------------------------
*
*  Custom ACF postbox header
*
*---------------------------------------------------------------------------------------------*/
.acf-postbox-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-sizing: border-box;
	min-height: 64px;
	margin: {
		top: -24px;
		right: -24px;
		bottom: 0;
		left: -24px;
	}
	padding: {
		top: 0;
		right: 24px;
		bottom: 0;
		left: 24px;
	}
	border-bottom: {
		width: 1px;
		style: solid;
		color: $gray-200;
	}

	h2.acf-postbox-title {
		margin: {
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
		padding: {
			top: 0;
			right: 24px;
			bottom: 0;
			left: 0;
		}
		@extend .acf-h3;
		color: $gray-700;
	}

	.rtl & h2.acf-postbox-title {
		padding: {
			right: 0;
			left: 24px;
		}
	}

	.acf-icon {
		background-color: $gray-400;
	}
}

/*---------------------------------------------------------------------------------------------
*
*  Screen options button & screen meta container
*
*---------------------------------------------------------------------------------------------*/
.acf-admin-page {
	#screen-meta-links {
		margin: {
			right: 32px;
		}

		.show-settings {
			border-color: $gray-300;
		}

		@media screen and (max-width: 768px) {
			margin: {
				right: 16px;
				bottom: 0;
			}
		}
	}

	&.rtl #screen-meta-links {
		margin: {
			right: 0;
			left: 32px;
		}

		@media screen and (max-width: 768px) {
			margin: {
				right: 0;
				left: 16px;
			}
		}
	}

	#screen-meta {
		border-color: $gray-300;
	}
}

/*---------------------------------------------------------------------------------------------
*
*  Postbox headings
*
*---------------------------------------------------------------------------------------------*/
.acf-admin-page {
	#poststuff {
		.postbox-header {
			h2,
			h3 {
				justify-content: flex-start;
				margin: {
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
				}
				padding: {
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
				}
				@extend .acf-h3;
				color: $gray-700 !important;
			}
		}
	}
}

/*---------------------------------------------------------------------------------------------
*
* Postbox drag state
*
*---------------------------------------------------------------------------------------------*/
.acf-admin-page {
	&.is-dragging-metaboxes
		.metabox-holder
		.postbox-container
		.meta-box-sortables {
		box-sizing: border-box;
		padding: 2px;
		outline: none;
		background-image: repeating-linear-gradient(
				0deg,
				$gray-500,
				$gray-500 5px,
				transparent 5px,
				transparent 10px,
				$gray-500 10px
			),
			repeating-linear-gradient(
				90deg,
				$gray-500,
				$gray-500 5px,
				transparent 5px,
				transparent 10px,
				$gray-500 10px
			),
			repeating-linear-gradient(
				180deg,
				$gray-500,
				$gray-500 5px,
				transparent 5px,
				transparent 10px,
				$gray-500 10px
			),
			repeating-linear-gradient(
				270deg,
				$gray-500,
				$gray-500 5px,
				transparent 5px,
				transparent 10px,
				$gray-500 10px
			);
		background-size: 1.5px 100%, 100% 1.5px, 1.5px 100%, 100% 1.5px;
		background-position: 0 0, 0 0, 100% 0, 0 100%;
		background-repeat: no-repeat;
		border-radius: $radius-lg;
	}

	.ui-sortable-placeholder {
		border: none;
	}
}

/*--------------------------------------------------------------------------------------------
*
*  Search summary
*
*--------------------------------------------------------------------------------------------*/
.acf-admin-page {
	.subtitle {
		display: inline-flex;
		align-items: center;
		height: 24px;
		margin: 0;
		padding: {
			top: 4px;
			right: 12px;
			bottom: 4px;
			left: 12px;
		}
		background-color: $blue-50;
		border: {
			width: 1px;
			style: solid;
			color: $blue-200;
		}
		border-radius: $radius-md;
		@extend .p3;

		strong {
			margin: {
				left: 5px;
			}
		}
	}
}

/*--------------------------------------------------------------------------------------------
*
*  Action strip
*
*--------------------------------------------------------------------------------------------*/
.acf-actions-strip {
	display: flex;

	.acf-btn {
		margin: {
			right: 8px;
		}
	}
}

/*--------------------------------------------------------------------------------------------
*
*  Notices
*
*--------------------------------------------------------------------------------------------*/
.acf-admin-page {
	.acf-notice,
	.notice,
	#lost-connection-notice {
		position: relative;
		box-sizing: border-box;
		min-height: 48px;
		margin: {
			top: 0 !important;
			right: 0 !important;
			bottom: 16px !important;
			left: 0 !important;
		}
		padding: {
			top: 13px !important;
			right: 16px;
			bottom: 12px !important;
			left: 50px !important;
		}
		background-color: #e7eff9;
		border: {
			width: 1px;
			style: solid;
			color: #9dbaee;
		}
		border-radius: $radius-lg;
		box-shadow: $elevation-01;
		color: $gray-700;

		&.update-nag {
			display: block;
			position: relative;
			width: calc(100% - 44px);
			margin: {
				top: 48px !important;
				right: 44px !important;
				bottom: -32px !important;
				left: 12px !important;
			}
		}

		.button {
			height: auto;
			margin: {
				left: 8px;
			}
			padding: 0;
			border: none;
			@extend .p5;
		}

		> div {
			margin: {
				top: 0;
				bottom: 0;
			}
		}

		p {
			flex: 1 0 auto;
			max-width: 100%;
			line-height: 18px;
			margin: 0;
			padding: 0;

			&.help {
				margin: {
					top: 0;
				}
				padding: {
					top: 0;
				}
				@extend .p7;
				color: rgba($gray-700, 0.7);
			}
		}

		// Dismiss button
		.acf-notice-dismiss,
		.notice-dismiss {
			position: absolute;
			top: 4px;
			right: 8px;
			padding: 9px;
			border: none;

			&:before {
				content: "";
				$icon-size: 20px;
				display: block;
				position: relative;
				z-index: 600;
				width: $icon-size;
				height: $icon-size;
				background-color: $gray-500;
				border: none;
				border-radius: 0;
				-webkit-mask-size: contain;
				mask-size: contain;
				-webkit-mask-repeat: no-repeat;
				mask-repeat: no-repeat;
				-webkit-mask-position: center;
				mask-position: center;
				-webkit-mask-image: url("../../images/icons/icon-close.svg");
				mask-image: url("../../images/icons/icon-close.svg");
			}

			&:hover::before {
				background-color: $gray-700;
			}
		}

		a.acf-notice-dismiss {
			position: absolute;
			top: 5px;
			right: 24px;

			&:before {
				background-color: $gray-600;
			}
		}

		// Icon base styling
		&:before {
			content: "";
			$icon-size: 16px;
			display: block;
			position: absolute;
			top: 15px;
			left: 18px;
			z-index: 600;
			width: $icon-size;
			height: $icon-size;
			margin: {
				right: 8px;
			}
			background-color: #fff;
			border: none;
			border-radius: 0;
			-webkit-mask-size: contain;
			mask-size: contain;
			-webkit-mask-repeat: no-repeat;
			mask-repeat: no-repeat;
			-webkit-mask-position: center;
			mask-position: center;
			-webkit-mask-image: url("../../images/icons/icon-info-solid.svg");
			mask-image: url("../../images/icons/icon-info-solid.svg");
		}

		&:after {
			content: "";
			display: block;
			position: absolute;
			top: 9px;
			left: 12px;
			z-index: 500;
			width: 28px;
			height: 28px;
			background-color: $color-info;
			border-radius: $radius-md;
			box-shadow: $elevation-01;
		}

		.local-restore {
			align-items: center;
			margin: {
				top: -6px;
				bottom: 0;
			}
		}
	}

	// Persisted notices should be hidden by default as they will be shown by JS if required.
	.notice[data-persisted="true"] {
		display: none;
	}

	.notice.is-dismissible {
		padding: {
			right: 56px;
		}
	}

	// Success notice
	.notice.notice-success {
		background-color: #edf7ef;
		border-color: #b6deb9;

		&:before {
			-webkit-mask-image: url("../../images/icons/icon-check-circle-solid.svg");
			mask-image: url("../../images/icons/icon-check-circle-solid.svg");
		}

		&:after {
			background-color: $color-success;
		}
	}

	// Error notice
	.acf-notice.acf-error-message,
	.notice.notice-error,
	#lost-connection-notice {
		background-color: #f7eeeb;
		border-color: #f1b6b3;

		&:before {
			-webkit-mask-image: url("../../images/icons/icon-warning.svg");
			mask-image: url("../../images/icons/icon-warning.svg");
		}

		&:after {
			background-color: $color-danger;
		}
	}
	
	.notice.notice-warning {
		&:before {
			-webkit-mask-image: url("../../images/icons/icon-alert-triangle.svg");
			mask-image: url("../../images/icons/icon-alert-triangle.svg");
			background: #f56e28;
		}

		&:after {
			content: none;
		}

		background: linear-gradient(0deg, rgba(247, 144, 9, 0.08), rgba(247, 144, 9, 0.08)), #FFFFFF;
		border: 1px solid rgba(247, 144, 9, 0.32);
		color: $gray-700;
	}
}

.acf-admin-single-taxonomy,
.acf-admin-single-post-type,
.acf-admin-single-options-page {
	.notice-success {
		.acf-item-saved-text {
			font-weight: 600;
		}

		.acf-item-saved-links {
			display: flex;
			gap: 12px;

			a {
				text-decoration: none;
				opacity: 1;

				&:after {
					content: "";
					width: 1px;
					height: 13px;
					display: inline-flex;
					position: relative;
					top: 2px;
					left: 6px;
					background-color: $gray-600;
					opacity: 0.3;
				}

				&:last-child {
					&:after {
						content: none;
					}
				}
			}
		}
	}
}

.rtl.acf-field-group,
.rtl.acf-internal-post-type {
	.notice {
		padding-right: 50px !important;

		.notice-dismiss {
			left: 8px;
			right: unset;
		}

		&:before {
			left: unset;
			right: 10px;
		}

		&:after {
			left: unset;
			right: 12px;
		}
	}

	&.acf-admin-single-taxonomy,
	&.acf-admin-single-post-type,
	&.acf-admin-single-options-page {
		.notice-success .acf-item-saved-links a {
			&:after {
				left: unset;
				right: 6px;
			}
		}
	}
}

/*--------------------------------------------------------------------------------------------
*
*  ACF PRO label
*
*--------------------------------------------------------------------------------------------*/
.acf-pro-label {
	display: inline-flex;
	align-items: center;
	min-height: 22px;
	border: none;
	font-size: 11px;
	text-transform: uppercase;
	text-decoration: none;
	color: #fff;
}

/*--------------------------------------------------------------------------------------------
*
*  Inline notice overrides
*
*--------------------------------------------------------------------------------------------*/
.acf-admin-page {
	.acf-field {
		// notice
		.acf-notice {
			display: flex;
			align-items: center;
			min-height: 40px !important;
			margin: {
				bottom: 6px !important;
			}
			padding: {
				top: 6px !important;
				left: 40px !important;
				bottom: 6px !important;
			}
			margin: 0 0 15px;
			background: #edf2ff;
			color: $gray-700 !important;
			border-color: #2183b9;
			border-radius: $radius-md;

			&:after {
				top: 8px;
				left: 8px;
				width: 22px;
				height: 22px;
			}

			&:before {
				top: 12px;
				left: 12px;
				width: 14px;
				height: 14px;
			}

			// error
			&.-error {
				background: #f7eeeb;
				border-color: #f1b6b3;
			}

			// success
			&.-success {
				background: #edf7ef;
				border-color: #b6deb9;
			}

			// warning
			&.-warning {
				background: #fdf8eb;
				border-color: #f4dbb4;
			}
		}
	}
}
