/*---------------------------------------------------------------------------------------------
*
*  Global
*
*---------------------------------------------------------------------------------------------*/
.acf-admin-page {
	#wpcontent {
		line-height: 140%;
	}
}

/*---------------------------------------------------------------------------------------------
*
*  Links
*
*---------------------------------------------------------------------------------------------*/
.acf-admin-page {

	a {
		color: $blue-500;
	}

}

/*---------------------------------------------------------------------------------------------
*
*  Headings
*
*---------------------------------------------------------------------------------------------*/

.acf-h1 {
	font-size: 21px;
	font-weight: 400;
}

.acf-h2 {
	font-size: 18px;
	font-weight: 400;
}

.acf-h3 {
	font-size: 16px;
	font-weight: 400;
}

.acf-admin-page,
.acf-headerbar {

	h1 {
		@extend .acf-h1;
	}

	h2 {
		@extend .acf-h2;
	}

	h3 {
		@extend .acf-h3;
	}

}

/*---------------------------------------------------------------------------------------------
*
*  Paragraphs
*
*---------------------------------------------------------------------------------------------*/

.acf-admin-page {

	.p1 {
		font-size: 15px;
	}

	.p2 {
		font-size: 14px;
	}

	.p3 {
		font-size: 13.5px;
	}

	.p4 {
		font-size: 13px;
	}

	.p5 {
		font-size: 12.5px;
	}

	.p6 {
		font-size: 12px;
	}

	.p7 {
		font-size: 11.5px;
	}

	.p8 {
		font-size: 11px;
	}

}

/*---------------------------------------------------------------------------------------------
*
*  Page titles
*
*---------------------------------------------------------------------------------------------*/
.acf-page-title {
	@extend .acf-h2;
	color: $gray-700;
}

/*---------------------------------------------------------------------------------------------
*
*  Hide old / native WP titles from pages
*
*---------------------------------------------------------------------------------------------*/
.acf-admin-page {

	.acf-settings-wrap h1 {
		display: none !important;
	}

	#acf-admin-tools h1:not(.acf-field-group-pro-features-title, .acf-field-group-pro-features-title-sm) {
		display: none !important;
	}

}

/*---------------------------------------------------------------------------------------------
*
*  Small
*
*---------------------------------------------------------------------------------------------*/
.acf-small {
	@extend .p6;
}

/*---------------------------------------------------------------------------------------------
*
*  Link focus style
*
*---------------------------------------------------------------------------------------------*/
.acf-admin-page {
	a:focus {
		box-shadow: none;
		outline: none;
	}

	a:focus-visible {
		box-shadow: 0 0 0 1px #4f94d4, 0 0 2px 1px rgb(79 148 212 / 80%);
		outline: 1px solid transparent;
	}
}
